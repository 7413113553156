import React, { useState, useEffect, useRef, useLayoutEffect } from "react";
import { withTranslation } from "react-i18next";
import { withRouter } from "react-router";
import {
  Header,
  Banner,
  Plans,
  About,
  Contact,
  Footer,
  Scrollbutton,
} from "../../components/replica";
import { useParams } from "react-router-dom";
import ReplicaSiteService from "../../../service/replica/replica";
import AppInfoservice from "../../../service/common/Appinfo";
import DashboardModel from "../../components/dashboard/DashboardModal";
import AddForm from "../../components/dashboard/AddForm";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

function Home() {
  const { user_name, admin } = useParams();
  // const params = useParams();
  const service = new ReplicaSiteService();
  const app = new AppInfoservice();
  const { t } = useTranslation();
  const Lang = useSelector((state) => state.repLang);
  const currentLangId = Lang?.languages?.find(
    (obj) => obj.code == Lang?.currentLang
  );
  console.log("currentLangId",currentLangId)

  
  const [state, setState] = useState({
    header_data: [],
    policy_data: [],
    banner_data: [],
    loding: true,
    logo: "",
    show: Cookies.get("button") == undefined ? true : false,
  });

  useEffect(() => {
  
    let admin_username = user_name ? user_name : "";
   
     service.getApi(admin_username).then((res) => {
      if (res.status) {
        localStorage.setItem("apiKey", res.data.key);
        service.getHeader(user_name, currentLangId?.id).then((res) => {
          if (res.status) {
            setState((prevState) => ({
              ...prevState,
              header_data: res.data,
              loding: false,
            }));
          } else {
            //alert("Invalid Url");
          }
          app.Appinfo().then((res) => {
            if (res.status) {
              setState((prevState) => ({
                ...prevState,
                logo: res.data.company_info.login_logo,
              }));
            }
          });
        });
        getBanner();
        getPolicy(currentLangId?.id);
      } else {
        alert("Invalid Admin user name");
      }
    });
    
   
  }, [currentLangId]);

  const getBanner = () => {
    service.getBanner(user_name).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          banner_data: res.data,
        }));
      } else {
        alert("Invalid Url");
      }
    });
  };
  const getPolicy = () => {
    service.getPolicy(user_name ,currentLangId?.id).then((res) => {
      if (res.status) {
        setState((prevState) => ({
          ...prevState,
          policy_data: res.data,
        }));
      } else {
      }
    });
  };
  const cancel = () => {
    setState((prevState) => ({
      ...prevState,
      show: false,
    }));
    Cookies.set("button", "false", { expires: 7 });
  };

  return (
    <div className="h-100">
      {!state.loding && (
        <>
          <DashboardModel show={state.show} title={"Welcome...!"}>
            <AddForm
              title={t("addFromTitle")}
              signup={state.header_data.regsitration_url}
              buynow={state.header_data.buynow_url}
              cancel={cancel}
            ></AddForm>
          </DashboardModel>

          {state.header_data && (
            <Header
              data={state.header_data}
              user_name={user_name}
              logo={state.logo}
              showmenu={true}
              buynow={state.header_data.buynow_url}
            />
          )}
          {state.banner_data && state.policy_data && (
            <Banner data={state} header_data={state.header_data} />
          )}
          {state.policy_data && <Plans data={state} />}
          {state.policy_data && <About data={state} />}
          {state.policy_data && <Contact data={state} user_name={user_name} />}
          <Scrollbutton />
          {state.policy_data && <Footer data={state} user_name={user_name} />}
        </>
      )}
    </div>
  );
}

export default withTranslation()(withRouter(Home));
