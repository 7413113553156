import React, { useState ,useEffect} from "react";
import { Card, Row, Col, Form, Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import style from "../../components/tree/tree.module.scss";
import { useSelector } from "react-redux";
import { Multiselect } from "multiselect-react-dropdown";

function TitleHeaders(props) {
  const { currentTheme } = useSelector((state) => state.Theme);
  const { t } = useTranslation();
  const [level, setLevel] = useState("all");
  const [loaders, setLoaders] = useState({
    resetloader: false,
    searchloader: false,
  });
  const [selected, setSelected] = useState([]);
  const [selectedSubcategory, setSelectedSubcategory] = useState([]);

  //change level
  const changeLevel = (level) => {
    setLevel(level.target.value);
  };

  const reset = () => {
    setLoaders((prev) => ({
      ...prev,
      resetloader: true,
    }));
    setTimeout(() => {
      setLevel("all");
      props.reset();
      setLoaders((prev) => ({
        ...prev,
        resetloader: false,
      }));
    }, 1000);
  };
  useEffect(() => {
  },[selected])

  const submitHandler = () => {
    props.onSubmit(selected[0]?.id,selectedSubcategory[0]?.id)
  }
  return (
    <Card className={style.titlepanel}>
      <Card.Body className={style.panelBody}>
        <Row>
          <Col md={4} sm={12} >
            <p>Category</p>
            <Multiselect
              options={props.dataVlaues[0]}
              value={selected}
              onSelect={setSelected}
              labelledBy="Select"
              displayValue="name"
              isSingle={true}
            />
            
          </Col>
         { console.log("selected",selected)}
          {selected[0]?.subcategories?.length>0 &&(
           <Col md={4} sm={12}>
            <p>Sub Category</p>
            <Multiselect
              options={selected[0]?.subcategories}
              value={selectedSubcategory}
              onSelect={setSelectedSubcategory}
             displayValue="name"
              isSingle={true}
            />
              
          </Col>
          )
            
          
}

           <Col md={4} sm={12} style={{display:"flex"}}>
          
            <Button
            style={{
                margin:"17px",
                height:"39px",
                 alignSelf: "center",
            }}
          variant="info"
          className={style.padding5}
          onClick={submitHandler}
         // disabled={loader.EarningsTable.search}
        >
        

          {t("Button.search")}
        </Button>
               <Button
               style={{
                margin:"17px",
                height:"39px"
            }}
          variant="info"
          className={style.padding5}
         // onClick={submitHandler}
         // disabled={loader.EarningsTable.search}
        >
        

          {t("Button.reset")}
        </Button>
            
          </Col>
          {/* <Col md={4} sm={12}>
            <div className={style.searchRight}>
            <div className={style.paddingSmall}>
              <Form.Group className={style.formGroup}>
                <Form.Label>{t("Common.Search")}</Form.Label>
                <Form.Control
                  style={{
                    height: "34px",
                  }}
                  type="text"
                  className={style.dropDownSelect}
                  onChange={(e) => props.changeDropDown(e.target.value)}
                  value={props.userName}
                  placeholder="Search by Member Name,Sponsor,Rank"
                ></Form.Control>
              </Form.Group>
                 </div>
                 <div className={style.paddingSmall}>
                <Form.Group className={style.formGroup}>
                  <Button
                    className={`${style.Button} ${style.BtnPrimary}`}
                    disabled={props.searchloader}
                    onClick={() => props.userParamsSearch()}
                  >
                   
                    {t("Button.search")}
                  </Button>
                  <Button
                    className={`${style.Button} ${style.BtnInfo}`}
                    disabled={loaders.resetloader}
                    onClick={props.resetParams}
                  >
         
                    {t("Button.reset")}
                  </Button>
                </Form.Group>
              </div>
         
            </div>
          </Col>
          <Col md={4} sm={12}>
            <div className={style.searchRight}>
              <div className={style.paddingSmall}>
                <Form.Group className={style.formGroup}>
                  <Form.Label>{t("Common.level")}</Form.Label>
                  <Form.Control
                    as="select"
                    className={style.dropDownSelect}
                    onChange={changeLevel}
                    value={level}
                  >
                    <option value="all">{t("Common.all")}</option>
                    {props &&
                      props.levels.map((items) => (
                        <option value={items} key={items}>
                          {items}
                        </option>
                      ))}
                  </Form.Control>
                </Form.Group>
              </div>
              <div className={style.paddingSmall}>
                <Form.Group className={style.formGroup}>
                  <Button
                    className={`${style.Button} ${style.BtnPrimary}`}
                    disabled={props.searchloader}
                    onClick={() => props.searchLevel("level", level)}
                  >
                    {props.searchloader ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      ""
                    )}
                    {t("Button.search")}
                  </Button>
                  <Button
                    className={`${style.Button} ${style.BtnInfo}`}
                    disabled={loaders.resetloader}
                    onClick={reset}
                  >
                    {loaders.resetloader ? (
                      <i
                        className="fa fa-refresh fa-spin"
                        style={{ marginRight: "5px" }}
                      />
                    ) : (
                      ""
                    )}

                    {t("Button.reset")}
                  </Button>
                </Form.Group>
              </div>
            </div>
          </Col> */}
        </Row>
      </Card.Body>
    </Card>
  );
}

export default TitleHeaders;
