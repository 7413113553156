import React, { useEffect, useState } from "react";
import style from "./replica.module.scss";
import { Row, Col, Container, Navbar } from "react-bootstrap";
import { IMG_URL } from "../../../configuration/apiconfig";
import { useTranslation, withTranslation } from "react-i18next";


function Banner(props) {
   const { t } = useTranslation();
  return (
    <section
      id="home"
      className="banner_section"
      style={{
        backgroundImage: `url(${props.data.banner_data.banner})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundAttachment: "fixed", // Ensures a parallax effect

      }}
    >
      <div className="container">
        <div className="banner_contant_sec">
          <h3 style={{ color: "white" }}>
            {props.data.header_data &&
              props.data.header_data.content?.home_title1}
          </h3>
          <h1 style={{ color: "white" }}>
            {props.data.header_data &&
              props.data.header_data.content?.home_title2}{" "}
          </h1>
          <a
            target="_blank"
            className="banner_button"
            href={props.data.header_data.buynow_url}
            rel="noreferrer"
          >
            {t("BuyNow")}
          </a>
        </div>
      </div>
    </section>
  );
}

export default Banner;
