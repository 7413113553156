import React, { useEffect } from "react";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { Dropdown } from "react-bootstrap";
import { changeLang, addReplLang, changeReplLang } from "../../../store/action";
import AppService from "../../../service/common/GetApi";
import { useHistory } from "react-router-dom";
//service
import AppInfoservice from "../../../service/common/Appinfo";
import ReplicaSiteService from "../../../service/replica/replica";
//styels
import style from "./replica.module.scss";
//custum lang dropsown lang button
const LangToggle = React.forwardRef(({ children, onClick }, ref) => (
  <span
    className={style.selectedLang}
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {/* Render custom icon here */}
    {children}
    <span className={style.caretIcon}></span>
  </span>
));

const Language = ({ classname, ismainlayout, user_name }) => {
  const { i18n } = useTranslation();
  const Lang = useSelector((state) => state.repLang); //current language
  const dispatch = useDispatch(); //redux state change
  const history = useHistory();
  useEffect(() => {
    const service = new AppInfoservice();
    service.Appinfo().then((res) => {
      if (res.status) {
        if (res.data.lang_status) {
          dispatch(addReplLang(res.data.languages));
        }
      } else {
        if (res.error.code === 1001) {
          history.push("/logout");
        } else if (res.error.code === 1002) {
          history.push("/logout");
        }
      }
    });
  }, [dispatch, history]);
  const classes = classNames(classname);
  // if (localStorage.getItem('i18nextLng') !== Lang.currentLang) {
  //     i18n.changeLanguage(Lang.currentLang);
  // }
  //language change handler
  function chagelang(lang, id) {
    const service = new ReplicaSiteService();
    let idData = {
      lang_id: parseInt(id),
      replica_user: user_name,
    };
    i18n.changeLanguage(lang);
     dispatch(changeReplLang(lang));
    service.setLanguage(idData).then((res) => {
      if (res.status) {
       
        // i18n.changeLanguage(lang);
      }
    });
  }
  return (
    <div className={style.langContainer}>
      {Lang.languages && (
        <Dropdown className={`${classes} ${style.langButton}`}>
          <Dropdown.Toggle as={LangToggle}>
            <img
              src={
                require("../../../assets/images/flags/" +
                  Lang.currentLang +
                  ".png").default
              }
              alt=""
              className={style.langFlag}
            />
          </Dropdown.Toggle>

          <Dropdown.Menu
            className={`animate__animated animate__fadeInRight ${style.languageDropDown}`}
          >
            {Lang.languages.map((value, index) => {
              return (
                <Dropdown.Item
                  key={index}
                  onClick={() => chagelang(value.code, value.id)}
                >
                  <img
                    src={
                      require("../../../assets/images/flags/" +
                        value.code +
                        ".png").default
                    }
                    alt=""
                  />
                  <span className="pl-1">{value.label}</span>
                </Dropdown.Item>
              );
            })}
          </Dropdown.Menu>
        </Dropdown>
      )}
    </div>
  );
};

export default Language;
